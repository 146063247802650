<template>
  <div data-app>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="6" md="6" style="color: #e33354" class="ml-4">
            <h3>{{ cardTitle }}</h3>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-card
          v-for="setting in settings"
          :key="setting.id"
          class="mx-auto mb-7"
          max-width="650"
          outlined
          elevation="15"
        >
          <v-list-item three-line>
            <v-list-item-content>
              <!-- <div class="text-overline mb-4">{{ setting.id }}</div> -->
              <v-list-item-title class="text-h5 mb-6">
                {{ setting.id }}
              </v-list-item-title>
              <!-- <v-divider></v-divider> -->
              <v-list-item-subtitle>
                <v-row v-for="language in languages" :key="language.lang">
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-if="
                        setting.setting_content && setting.setting_content.name
                      "
                      v-model="setting.setting_content.name[language.lang]"
                      :label="$t('FORMS.name') + ' (' + language.lang + ')'"
                      :rules="nameRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-if="
                        setting.setting_content &&
                        setting.setting_content.url_prefix
                      "
                      v-model="
                        setting.setting_content.url_prefix[language.lang]
                      "
                      :label="
                        $t('FORM_INPUT_NAMES.url_prefix') +
                        ' (' +
                        language.lang +
                        ')'
                      "
                      :rules="nameRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-card-actions>
            <v-btn
              v-if="permissionCan('update')"
              color="primary"
              elevation="2"
              @click="handleSaveSetting(setting)"
            >
              {{ $t("FORMS.save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
        <!-- <pre>
          {{ settings }}
        </pre> -->
      </v-card-text>
    </v-card>
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import SiteService from "@/core/services/site.service.js";
import ApiService from "@/core/services/api.service";
import i18nService from "@/core/services/i18n.service.js";

export const PERMISSION_TO = "blog.setting.";
export const INITIAL_SETTINGS = [];
export default {
  name: "blogSettngs",
  data() {
    return {
      languages: i18nService.userLanguages,
      cardTitle: this.$t("MENU.SETTINGS") + ": " + this.$t("MENU.BLOGS"),
      routePath: "/cms/blog/tags/",
      permissionTo: PERMISSION_TO,
      permissions: JSON.parse(localStorage.permissions),
      loader: true,
      settings: INITIAL_SETTINGS,
    };
  },

  computed: {
    endPoint() {
      return "blog/admin/" + SiteService.getActiveSiteId() + "/setting";
    },
  },

  watch: {
    dialogDelete(val) {
      val || this.handleCloseDelete();
    },
    "$route.params.id": {
      handler: function (id) {
        if (id) {
          this.editItem(id);
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    fetchSettings() {
      ApiService.get(this.endPoint)
        .then(({ data }) => {
          this.settings = Object.assign({}, data);
        })
        .catch((error) => {
          console.log("Error!: ", error);
        })
        .finally(() => {
          this.loader = false;
        });
      this.loader = false;
    },

    handleSaveSetting(setting) {
      this.loader = true;
      ApiService.put(this.endPoint + "/" + setting.id, setting)
        .then(() => {})
        .catch((error) => {
          if (error.response) {
            let errors = error.response.data;
            if (errors) {
              for (let field in errors.errors) {
                this.setError(field, errors.errors[field][0]);
              }
            }
          }
        })
        .finally(() => {
          this.loader = false;
        });
      console.log(setting);
      // this.loader = false;
    },

    permissionCan(action) {
      return this.permissions.find((x) => x.name == this.permissionTo + action);
    },
  },

  mounted() {
    this.loader = true;
    this.fetchSettings();
  },
};
</script>
